import React, { useState, useMemo, useCallback, useEffect } from 'react';

import SelectCheckboxIntegracao from '@/features/enterprise/components/SelectCheckboxIntegracao';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import { Content, Wrapper } from './styles';

interface FotoceuticosProps {
  fotoceuticos: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaFotoceuticosFhir: React.FC<FotoceuticosProps> = ({
  fotoceuticos,
  passo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: fotoceuticos, passoExecutado: passo });

  const [
    { fotoceuticosStatus },
    ,
    { updateFotoceuticosStatus },
  ] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map(
      (fotoceutico, index) => {
        const option = {
          key: `${index}`,
          title: fotoceutico.titulo,
          detail: fotoceutico.descricao,
          categoria: fotoceutico.categoria,
          value: fotoceutico.id,
          pedido: fotoceutico.pedido,
          selectedDefault: !!fotoceutico.solicitacao_pre_selecionada,
        } as OptionIntegracao;

        return toOptionIntegracao(option);
      },
    );
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback((opts: OptionIntegracao[]) => {
    setSelectedOptions(opts);
  }, []);

  useEffect(() => {
    updateFotoceuticosStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions && integrationStatus !== 'disabled') {
      changeIntegrationStatus(selectedOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Wrapper>
      <Content>
        <SelectCheckboxIntegracao
          type="Fotoceutico"
          options={options}
          generalStatus={passo.executado ? 'disabled' : fotoceuticosStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaFotoceuticosFhir;
