import colors from '@/design/colors';
import { HeadingSmall } from '@/design/typography';
import styled from 'styled-components';

export const Box = styled.div`
  background-color: ${colors.gray50};
  border-radius: 8px;
  padding: 9px 16px;
  display: flex;
  flex-direction: row;
  row-gap: 8px;

  /* Default color */
  ${HeadingSmall(14)}
  color: ${colors.blackAlpha100};
`;

export const Title = styled.h2`
  ${HeadingSmall(14)};
  color: ${colors.black};
`;

export const Control = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  column-gap: 16px;
  transition: color 100ms linear;
  width: fit-content;

  ${HeadingSmall(14)};
  color: ${colors.blackAlpha100};

  &:hover:not(:disabled) {
    color: ${colors.purple100};
  }

  &:disabled {
    cursor: default;
  }
`;
