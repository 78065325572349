import React from 'react';

import AutoScrollList, { ListRenderItem } from '@/components/AutoScrollList';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import { IntegrationProvider } from '@/features/enterprise/contexts/IntegrationContext';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { PassoExecutado } from '@/models/PassoExecutado';

import CondutaMenu from '@/features/complementares/components/CondutaMenu';
import { useIsCondutaComplementarEnabled } from '@/features/complementares/helpers/permissions';
import { PerguntaProvider } from '../../providers/PerguntaProvider';
import IdentificadorProtocoloEnterprise from '../IdentificadorProtocoloEnterprise';
import PassoExecutadoDetail from '../PassoExecutadoDetail';
import ProtocoloFinalizadoEnterprise from '../ProtocoloFinalizadoEnterprise';
import ProtocoloInterrompidoEnterprise from '../ProtocoloInterrompidoEnterprise';

interface Step {
  type: 'initial' | 'execution' | 'final';
  object?: PassoExecutado;
}

const ProtocoloExecutadoDetail: React.FC = () => {
  const { user } = useAuth();
  const [
    {
      protocoloExecutado,
      passosExecutados,
      isProtocoloExecutadoRetomado,
      isProtocoloReadOnly,
    },
  ] = useExecucaoContext();
  const isCondutaEnabled = useIsCondutaComplementarEnabled();

  const [tagsFormFilled, setTagsFormFilled] = React.useState(false);

  const tagsProtocolo = React.useMemo(() => {
    if (user && user.empresa) {
      const { tags_protocolo = [] } = user.empresa;
      return tags_protocolo;
    }

    return [];
  }, [user]);

  const shouldDisplayExecution = React.useMemo(() => {
    if (isProtocoloExecutadoRetomado || isProtocoloReadOnly) {
      return true;
    }

    return (
      (tagsProtocolo.length > 0 && tagsFormFilled) ||
      !tagsProtocolo.length ||
      !!protocoloExecutado?.tags.length
    );
  }, [
    protocoloExecutado,
    isProtocoloExecutadoRetomado,
    isProtocoloReadOnly,
    tagsFormFilled,
    tagsProtocolo.length,
  ]);

  const initialItems: Step[] = React.useMemo(() => {
    if (tagsProtocolo.length > 0 && !isProtocoloReadOnly) {
      return [{ type: 'initial' }];
    }

    return [];
  }, [tagsProtocolo.length, isProtocoloReadOnly]);

  const finalItems: Step[] = React.useMemo(() => {
    if (isProtocoloReadOnly) {
      if (protocoloExecutado && !!protocoloExecutado.data_execucao) {
        return [{ type: 'final' }];
      }

      return [];
    }
    if (protocoloExecutado && !protocoloExecutado.passo_corrente) {
      return [{ type: 'final' }];
    }

    return [];
  }, [protocoloExecutado, isProtocoloReadOnly]);

  const executionItems = React.useMemo(() => {
    if (shouldDisplayExecution) {
      return passosExecutados.map<Step>(passo => ({
        type: 'execution',
        object: passo,
      }));
    }

    return [];
  }, [passosExecutados, shouldDisplayExecution]);

  const items = React.useMemo(() => {
    return [...initialItems, ...executionItems, ...finalItems];
  }, [executionItems, initialItems, finalItems]);

  const renderPasso = React.useCallback((data: PassoExecutado) => {
    return (
      <IntegrationProvider>
        <PerguntaProvider>
          <PassoExecutadoDetail passoExecutado={data} />
        </PerguntaProvider>
      </IntegrationProvider>
    );
  }, []);

  const renderTagForm = React.useCallback(() => {
    return (
      <IdentificadorProtocoloEnterprise
        tags={tagsProtocolo}
        callbackIniciar={() => setTagsFormFilled(true)}
      />
    );
  }, [tagsProtocolo]);

  const renderFinalStep = React.useCallback(() => {
    if (protocoloExecutado && protocoloExecutado.status === 'CANCELADO') {
      return <ProtocoloInterrompidoEnterprise />;
    }

    if (isProtocoloReadOnly) {
      return <ProtocoloFinalizadoEnterprise />;
    }

    return null;
  }, [isProtocoloReadOnly, protocoloExecutado]);

  const renderItem = React.useCallback(
    ({ item: { type, object } }: ListRenderItem<Step>) => {
      switch (type) {
        case 'initial':
          return renderTagForm();
        case 'final':
          return renderFinalStep();
        default:
          if (object) {
            return renderPasso(object);
          }
          return null;
      }
    },
    [renderPasso, renderFinalStep, renderTagForm],
  );

  const keyExtractor = React.useCallback(
    ({ item: { type, object } }: ListRenderItem<Step>) => {
      switch (type) {
        case 'execution':
          if (object) {
            return object.id;
          }
          return null;
        default:
          return type;
      }
    },
    [],
  );

  return (
    <>
      <AutoScrollList
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        options={{
          topOffset: 24,
          itemSeparatorGap: 24,
          disableAutoScroll: isProtocoloReadOnly,
        }}
      />
      {isCondutaEnabled && <CondutaMenu />}
    </>
  );
};

ProtocoloExecutadoDetail.defaultProps = {
  isReadOnly: false,
};

export default ProtocoloExecutadoDetail;
