/* eslint-disable import/no-duplicates */
import fhirpath from 'fhirpath';
import { ptBR } from 'date-fns/locale';
import { parseISO, format, intervalToDuration } from 'date-fns';

import PatientMaleIllustration from '@/assets/illustrations/ui/patients/masculine.svg';
import PatientFemaleIllustration from '@/assets/illustrations/ui/patients/feminine.svg';
import PatientNeutroIllustration from '@/assets/illustrations/ui/patients/undefined.svg';

import DoctorMaleIllustration from '@/assets/illustrations/ui/doctors/masculine.svg';
import DoctorFemaleIllustration from '@/assets/illustrations/ui/doctors/feminine.svg';
import DoctorNeutroIllustration from '@/assets/illustrations/ui/doctors/undefined.svg';

import { toNameCase } from '@/utils/string';
import { buildFullName } from '../fhir/helpers/datatypes';

interface PatientJsonType {
  nome: string;
  data: string;
  idade: string;
  genero: string;
}
interface PractitionerJsonType {
  nome: string;
  identificador: string | undefined;
  especialidade: string;
  genero: string;
}

export function getAgeLabel(birthDate: string | undefined): string {
  let ageLabel = '';

  if (!birthDate) {
    return '';
  }

  const { years = 0, months = 0, days = 0 } = intervalToDuration({
    start: new Date(birthDate),
    end: new Date(),
  });

  if (years > 0) {
    ageLabel += `${years} ano${years > 1 ? 's' : ''}`;
  }

  if (months > 0) {
    const prefix = years > 0 ? ' e ' : '';
    ageLabel += `${prefix}${months} ${months > 1 ? 'meses' : 'mês'}`;
  }

  // Paciente recém nascido (informar idade em dias)
  if (!ageLabel && days > 0) {
    ageLabel += `${days} dia${days > 1 ? 's' : ''}`;
  }

  return ageLabel;
}

export function getPatientAvatar(gender: fhir4.Patient['gender']): string {
  if (gender && ['male', 'female'].includes(gender)) {
    return gender === 'male'
      ? PatientMaleIllustration
      : PatientFemaleIllustration;
  }

  return PatientNeutroIllustration;
}

export function getDoctorAvatar(gender: fhir4.Practitioner['gender']): string {
  if (gender && ['male', 'female'].includes(gender)) {
    return gender === 'male'
      ? DoctorMaleIllustration
      : DoctorFemaleIllustration;
  }

  return DoctorNeutroIllustration;
}

export const genderDisplay = (gender: string | undefined): string => {
  const displayMapping = {
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outro',
    unknown: 'Desconhecido',
  } as Record<string, string>;

  if (!gender) {
    return displayMapping.unknown;
  }

  return displayMapping[gender];
};

export const toLocaleDate = (dateString: string | undefined): string => {
  if (dateString) {
    return format(parseISO(dateString), 'dd/MM/yyyy', { locale: ptBR });
  }

  return '';
};

export const patientToJson = (
  encounter: fhir4.Encounter | null,
): PatientJsonType => {
  if (!encounter) {
    return { nome: '', genero: '', data: '', idade: '' };
  }

  const humanName = fhirpath.evaluate(
    encounter,
    "Encounter.contained.ofType(Patient).name.where(use='official').first()" +
      ' | Encounter.contained.ofType(Patient).name.first()',
  )[0] as fhir4.HumanName;

  const gender = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Patient).gender',
  )[0] as fhir4.Patient['gender'];

  const birthDate = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Patient).birthDate.first()',
  )[0] as fhir4.Patient['birthDate'];

  return {
    nome: humanName ? buildFullName(humanName) : '',
    genero: genderDisplay(gender),
    data: toLocaleDate(birthDate),
    idade: getAgeLabel(birthDate),
  };
};

export const practitionerToJson = (
  encounter: fhir4.Encounter | null,
): PractitionerJsonType => {
  if (!encounter) {
    return {
      nome: '',
      identificador: '',
      especialidade: '',
      genero: '',
    };
  }

  const humanName = fhirpath.evaluate(
    encounter,
    "Encounter.contained.ofType(Practitioner).name.where(use='usual').first()" +
      ' | Encounter.contained.ofType(Practitioner).name.first()',
  )[0] as fhir4.HumanName;

  const identifier = fhirpath.evaluate(
    encounter,
    "Encounter.contained.ofType(Practitioner).identifier.where(use='official').first()",
  )[0] as fhir4.Identifier;

  const specialtyText = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(PractitionerRole).specialty.text.first()' +
      '| Encounter.contained.ofType(PractitionerRole).specialty.coding.display.first()',
  )[0] as fhir4.CodeableConcept['text'];

  const gender = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Practitioner).gender',
  )[0] as fhir4.Practitioner['gender'];

  return {
    nome: humanName ? buildFullName(humanName) : '',
    identificador: identifier ? identifier.value : '',
    especialidade: specialtyText ? toNameCase(specialtyText) : '',
    genero: genderDisplay(gender),
  };
};

export interface PatientDisplayModel {
  officialName: string;
  usualName: string;
  gender: string;
  avatar: string;
  age: string;
  hasMinimalDisplayProperties?: boolean;
}

export interface DoctorDisplayModel {
  name: string;
  pronoun: string;
  specialty: string;
  avatar: string;
}

export interface OrganizationDisplayModel {
  organization: string;
  location: string;
}

function safeBuildFullName(names: fhir4.HumanName[]) {
  if (names.length) {
    return buildFullName(names[0]);
  }

  return '';
}

function defaultDoctorName(gender: fhir4.Practitioner['gender']) {
  return gender === 'female' ? 'Doutora' : 'Doutor';
}

export function patientDisplayModel(
  encounter: fhir4.Encounter | null,
): PatientDisplayModel {
  const defaultModel: PatientDisplayModel = {
    officialName: '',
    usualName: '',
    gender: '',
    avatar: '',
    age: '',
    hasMinimalDisplayProperties: false,
  };

  if (!encounter) {
    return defaultModel;
  }

  const patientEval = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Patient)',
  );

  if (!patientEval.length) {
    return defaultModel;
  }

  const patient = patientEval[0] as fhir4.Patient;

  const usualName = safeBuildFullName(
    fhirpath.evaluate(patient, "Patient.name.where(use='usual').first()"),
  );

  const officialName = safeBuildFullName(
    fhirpath.evaluate(
      patient,
      "Patient.name.where(use='official').first() | Patient.name.first()",
    ),
  );

  const { gender = 'unknown', birthDate } = patient;

  return {
    officialName,
    usualName,
    gender: genderDisplay(gender),
    avatar: getPatientAvatar(gender),
    age: getAgeLabel(birthDate),
    hasMinimalDisplayProperties: !!(usualName || officialName || birthDate),
  };
}

export function doctorDisplayModel(
  encounter: fhir4.Encounter | null,
): DoctorDisplayModel {
  const defaultModel = {
    name: '',
    pronoun: '',
    specialty: '',
    avatar: '',
  };

  if (!encounter) {
    return defaultModel;
  }

  const practitionerEval = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Practitioner)',
  );

  // Não faz sentido receber apenas a role (perfil) do médico
  // Ou seja, sem profissional, sem dados relevantes
  if (!practitionerEval.length) {
    return defaultModel;
  }

  const practitioner = practitionerEval[0] as fhir4.Practitioner;
  const { gender = 'unknown' } = practitioner;

  const name = safeBuildFullName(
    fhirpath.evaluate(
      practitioner,
      "Practitioner.name.where(use='official').first() | Practitioner.name.first()",
    ),
  );
  const specialtyEval = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(PractitionerRole).specialty.text.first()' +
      '| Encounter.contained.ofType(PractitionerRole).specialty.coding.display.first()',
  );

  const specialty = specialtyEval[0] || '';

  return {
    name,
    pronoun: defaultDoctorName(gender),
    specialty,
    avatar: getDoctorAvatar(gender),
  };
}

export function organizationDisplayModel(
  encounter: fhir4.Encounter | null,
): OrganizationDisplayModel {
  const defaultModel = {
    organization: '',
    location: '',
  };

  if (!encounter) {
    return defaultModel;
  }

  const organizationEval = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Organization).name',
  );

  const locationEval = fhirpath.evaluate(
    encounter,
    'Encounter.contained.ofType(Location).name',
  );

  return {
    organization: organizationEval[0] || '',
    location: locationEval[0] || '',
  };
}
