import React, { useState, useMemo, useCallback, useEffect } from 'react';

import SelectCheckboxIntegracao from '@/features/enterprise/components/SelectCheckboxIntegracao';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import { Content, Wrapper } from './styles';

interface EncaminhamentosProps {
  encaminhamentos: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaEncaminhamentosFhir: React.FC<EncaminhamentosProps> = ({
  encaminhamentos,
  passo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: encaminhamentos, passoExecutado: passo });

  const [
    { encaminhamentosStatus },
    ,
    { updateEncaminhamentosStatus },
  ] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map(
      (encaminhamento, index) => {
        const option = {
          key: `${index}`,
          title: encaminhamento.titulo,
          detail: encaminhamento.descricao,
          categoria: encaminhamento.categoria,
          value: encaminhamento.id,
          pedido: encaminhamento.pedido,
          selectedDefault: !!encaminhamento.solicitacao_pre_selecionada,
        } as OptionIntegracao;

        return toOptionIntegracao(option);
      },
    );
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback((opts: OptionIntegracao[]) => {
    setSelectedOptions(opts);
  }, []);

  useEffect(() => {
    updateEncaminhamentosStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions && integrationStatus !== 'disabled') {
      changeIntegrationStatus(selectedOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  return (
    <Wrapper>
      <Content>
        <SelectCheckboxIntegracao
          type="Encaminhamento"
          options={options}
          generalStatus={passo.executado ? 'disabled' : encaminhamentosStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaEncaminhamentosFhir;
