import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Alert } from '@/assets/illustrations/feedback-graphics/alert.svg';
import ProtocoloExecutadoDetail from '@/features/execucao/components/ProtocoloExecutadoDetail';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import ExecucaoService from '@/features/execucao/services/ExecucaoAPI';

import LoadingSwitchTransition from '@/components/LoadingSwitchTransition';
import PlaceholderLoading from '@/components/PlaceholderLoading';

import MedflowLogo from '@/components/Logo/MedflowLogo';
import Toast from '@/components/Toast';
import { closeMedflowApp } from '@/utils/window';
import { Slide, toast } from 'react-toastify';
import EnterpriseHeaderProtocoloExecutado from '../../components/EnterpriseHeaderProtocoloExecutado';
import { useEnterpriseContext } from '../../contexts/EnterpriseContext';
import EnterprisePageLayout from '../../layouts/EnterprisePageLayout';
import {
  CloseContainer,
  ErrorContainer,
  ProgressBar,
  ProgressBarContainer,
} from './styles';

const PlaceholderContent = () => (
  <PlaceholderLoading.Rect
    height={513}
    width="100%"
    style={{
      display: 'flex',
      borderRadius: 16,
    }}
  />
);

const ExecucaoProtocoloEnterprise: React.FC = () => {
  const history = useHistory();
  const protocoloId = useParams<{ id: string }>().id;

  const intervalRef = React.useRef<NodeJS.Timeout>();

  const [, { dispatchStartFromExecution }] = useEnterpriseContext();

  const [isLoadingProtocolo, setIsLoadingProtocolo] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isFinalizado, setIsFinalizado] = useState(false);
  const [closeProgress, setCloseProgress] = useState(0);

  const [
    { protocoloExecutado },
    { dispatchSetExecucao },
  ] = useExecucaoContext();

  const redirectToResumo = React.useCallback(() => {
    if (protocoloId) {
      toast.dark(
        () => (
          <Toast>
            <p>
              Este protocolo já foi finalizado. Você está visualizando a tela de
              resumo com as informações finais.
            </p>
          </Toast>
        ),
        {
          position: 'bottom-left',
          autoClose: 5000,
          transition: Slide,
        },
      );
      history.replace(`/enterprise/protocolos/${protocoloId}/resumo`);
    }
  }, [history, protocoloId]);

  React.useEffect(() => {
    if (protocoloExecutado) {
      setIsLoadingProtocolo(false);
    }
  }, [protocoloExecutado]);

  React.useEffect(() => {
    if (
      !isLoadingProtocolo &&
      protocoloExecutado &&
      protocoloExecutado.executado &&
      protocoloExecutado.status === 'FINALIZADO' &&
      !protocoloExecutado.passo_corrente
    ) {
      setIsFinalizado(true);
    }
  }, [isLoadingProtocolo, protocoloExecutado]);

  React.useEffect(() => {
    if (
      !isLoadingProtocolo &&
      protocoloExecutado &&
      protocoloExecutado.executado &&
      protocoloExecutado.passo_corrente
    ) {
      redirectToResumo();
    }
  }, [redirectToResumo, isLoadingProtocolo, protocoloExecutado]);

  React.useEffect(() => {
    if (!isLoadingProtocolo && !protocoloExecutado) {
      setIsError(true);
    }
  }, [isLoadingProtocolo, protocoloExecutado]);

  React.useEffect(() => {
    async function loadProtocolo() {
      try {
        const execucao = await ExecucaoService.getExecucao(protocoloId, {
          omit: ['passo_corrente'],
          expand: ['passos'],
        });
        dispatchSetExecucao(execucao);
        dispatchStartFromExecution(execucao);
      } catch {
        setIsError(true);
      }
    }

    loadProtocolo();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isFinalizado) {
      intervalRef.current = setInterval(() => {
        setCloseProgress(prev => prev + (prev === 66 ? 34 : 33));
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [isFinalizado]);

  React.useEffect(() => {
    if (closeProgress === 100) {
      clearInterval(intervalRef.current);
      if (protocoloExecutado) {
        closeMedflowApp(protocoloExecutado);
      }
    }
  }, [closeProgress, protocoloExecutado]);

  if (isError) {
    return (
      <ErrorContainer>
        <Alert />

        <h3>Ops, algo deu errado.</h3>

        <p>Por favor, tente novamente em alguns instantes.</p>
      </ErrorContainer>
    );
  }

  if (isFinalizado) {
    return (
      <CloseContainer>
        <MedflowLogo />
        <h3>Protocolo finalizado.</h3>

        <p>
          Estamos retornando os dados para o seu prontuário. O Medflow fecha em
          instantes.
        </p>

        <ProgressBarContainer>
          <ProgressBar progress={closeProgress} />
        </ProgressBarContainer>
      </CloseContainer>
    );
  }

  return (
    <EnterprisePageLayout Nav={EnterpriseHeaderProtocoloExecutado}>
      <LoadingSwitchTransition
        loading={!protocoloExecutado}
        LoadingComponent={<PlaceholderContent />}
        style={{
          flex: 1,
        }}
      >
        <ProtocoloExecutadoDetail />
      </LoadingSwitchTransition>
    </EnterprisePageLayout>
  );
};

export default ExecucaoProtocoloEnterprise;
