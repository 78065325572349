import styled from 'styled-components';

import { HeadingSmall } from '@/design/typography';
import colors from '@/design/colors';
import { InputContent } from '@/components/InputField/styles';

export const ArtifactFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const ArtifactFormTitle = styled.div`
  ${HeadingSmall(14)};
  color: ${colors.blackAlpha100};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  cursor: default;
`;

export const ArtifactFieldLayout = styled.div`
  flex: 0 1 calc(50% - 8px);

  textarea {
    resize: vertical;
  }

  ${InputContent} {
    width: 100%;
  }
`;

export const Fieldset = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
`;
