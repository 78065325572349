import React from 'react';

import SelectionTextBox, {
  SelectionTextBoxProps,
} from '@/components/SelectionTextBox';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  CategoriaContainer,
  CategoriaContent,
  CategoriaLastLink,
  CategoriaLink,
  CategoriaTitleLink,
  CollapseButton,
  CollapseContainer,
  CollapseDivisor,
  CollapseSelectedContainer,
  CollapseSelectedSpan,
  CollapseSpan,
  OptionsContainer,
} from './styles';

interface Props {
  name: string;
  artefatos: React.ReactNode[];
  intialConfig?: 'hidded' | 'expanded';
  totalSelected?: number;
  controlProps?: SelectionTextBoxProps['controlProps'];
}

const CategoriaArtefato: React.FC<Props> = ({
  name,
  artefatos,
  intialConfig = 'expanded',
  totalSelected,
  controlProps,
}) => {
  const tooltipText = React.useMemo(() => {
    const prefix =
      controlProps?.state === 'marked' ? 'Desmarcar' : 'Selecionar';

    return `${prefix} todos os itens desta categoria`;
  }, [controlProps?.state]);

  const [hoverContainer, setHoverContainer] = React.useState(false);
  const [isOpenedDetails, setIsOpenedDetails] = React.useState(
    intialConfig === 'expanded',
  );

  const onCollapseClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      setIsOpenedDetails(prev => !prev);
    },
    [],
  );

  const SelectedContainer = React.useMemo(() => {
    return (
      <TransitionGroup style={{ display: 'flex' }}>
        <CSSTransition
          unmountOnExit
          key={totalSelected ? 'active' : 'inactive'}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
        >
          {totalSelected ? (
            <CollapseSelectedContainer>
              <CollapseSelectedSpan>{totalSelected}</CollapseSelectedSpan>
              <CollapseDivisor>/</CollapseDivisor>
            </CollapseSelectedContainer>
          ) : (
            <CollapseSelectedContainer />
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  }, [totalSelected]);

  return (
    <CategoriaContainer>
      <OptionsContainer>
        <CategoriaTitleLink collapsed={isOpenedDetails} />
        <SelectionTextBox tooltip={tooltipText} controlProps={controlProps}>
          {name}
          <CollapseContainer>
            {SelectedContainer}
            <CollapseSpan
              hover={hoverContainer}
              onMouseEnter={() => {
                setHoverContainer(true);
              }}
              onMouseLeave={() => {
                setHoverContainer(false);
              }}
              onClick={onCollapseClick}
            >
              {artefatos.length}
            </CollapseSpan>
            <CollapseButton
              onMouseEnter={() => {
                setHoverContainer(true);
              }}
              onMouseLeave={() => {
                setHoverContainer(false);
              }}
              hover={hoverContainer}
              collapsed={isOpenedDetails}
              onClick={onCollapseClick}
            >
              {isOpenedDetails ? (
                <FiChevronDown size="18" />
              ) : (
                <FiChevronUp size="18" />
              )}
            </CollapseButton>
          </CollapseContainer>
        </SelectionTextBox>
      </OptionsContainer>

      <CategoriaContent collapsed={isOpenedDetails}>
        {artefatos.map((artefato, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <OptionsContainer key={index.toString()}>
            {index === artefatos.length - 1 ? (
              <CategoriaLastLink />
            ) : (
              <CategoriaLink />
            )}
            {artefato}
          </OptionsContainer>
        ))}
      </CategoriaContent>
    </CategoriaContainer>
  );
};

export default CategoriaArtefato;
