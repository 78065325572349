/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Button from '@/components/Button';
import Information from '@/components/Information';
import Markdown from '@/components/Markdown';
import ToastContent from '@/components/ToastContent';

import { PassoExecutado } from '@/models/PassoExecutado';
import { ProtocoloExecutado } from '@/models/ProtocoloExecutado';

import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';
import ErrorBox from '@/features/enterprise/components/ErrorBox';
import { useEnterpriseContext } from '@/features/enterprise/contexts/EnterpriseContext';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import {
  CondicaoSolicitacaoType,
  FhirArtefatoType,
} from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import {
  getPassoRespostaPayload,
  isValidRespostaPayload,
  listInvalidRespostaPayload,
} from '@/features/execucao/helpers/respostas';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import Feature from '@/features/features/components/Feature';

import { ExecutarPassoPayload, RespostaPayload } from '../../types';

import { usePerguntaContext } from '../../providers/PerguntaProvider';
import ExecucaoAPI from '../../services/ExecucaoAPI';

import ButtonInterromperProtocolo from '../ButtonInterromperProtocolo';
import ButtonOpenFlowchart from '../ButtonOpenFlowchart';
import ButtonSairProtocolo from '../ButtonSairProtocolo';
import ConditionAlert from '../ConditionAlert';
import PassoExecutadoBreakpoint from '../PassoExecutadoBreakpoint';

import BoxPerguntas, { BoxPerguntasRef } from './BoxPerguntas';
import BoxSolicitacoes from './BoxSolicitacoes';
import BoxSugestoesProtocolos from './BoxSugestoesProtocolos';
import ModalRedirecionarProtocolo from './BoxSugestoesProtocolos/ModalRedirecionarProtocolo';
import {
  Container,
  Content,
  DescriptionContainer,
  Footer,
  Header,
  RefazerButton,
} from './styles';
import { TipoPasso } from './types';

export interface PassoExecutadoDetailProps {
  passoExecutado: PassoExecutado;
}

export type PassoExecutadoDetailRef = HTMLDivElement;

const PassoExecutadoDetail = React.forwardRef<
  PassoExecutadoDetailRef,
  PassoExecutadoDetailProps
>(({ passoExecutado }, ref) => {
  const boxPerguntasRef = React.useRef<BoxPerguntasRef>(null);

  const { user } = useAuth();
  const [{ mode }, { dispatchStartFromExecution }] = useEnterpriseContext();
  const [
    { status, passosExecutados, protocoloExecutado, isProtocoloReadOnly },
    {
      dispatchExecutarPasso,
      dispatchRefazerPasso,
      dispatchPararPasso,
      dispatchProsseguirPasso,
      dispatchSolicitarFinalizacao,
      dispatchAlterarStatus,
      dispatchSetExecucao,
    },
  ] = useExecucaoContext();

  const [
    { generalStatus },
    ,
    { dispatchSolicitarRecursos, dispatchCancelarRecursos },
  ] = useIntegrationContext();

  const [payload, setPayload] = useState<RespostaPayload>(() =>
    getPassoRespostaPayload(passoExecutado),
  );
  const [selectedRedirects, setSelectedRedirects] = React.useState(() =>
    passoExecutado.opcoes_redirecionamento.filter(({ selected }) => selected),
  );
  const [{ allowNullItems }] = usePerguntaContext();

  const [inError, setInError] = useState(false);
  const [solicitacoes, setSolicitacoes] = useState(passoExecutado.solicitacoes);
  const [isProsseguirLoading, setIsProsseguirLoading] = useState(false);
  const [isCancelandoArtefatos, setIsCancelandoArtefatos] = useState(false);
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);

  const integracaoEnterpriseAtiva = useMemo(() => {
    return mode === 'integrated';
  }, [mode]);

  const canExecutar = useMemo(
    () =>
      status === 'active' &&
      isValidRespostaPayload(passoExecutado, payload, allowNullItems),
    [allowNullItems, passoExecutado, payload, status],
  );

  const enablePreescrever = useMemo(
    () => ['some_selected', 'all_selected', 'error'].includes(generalStatus),
    [generalStatus],
  );

  const enableRetry = useMemo(() => generalStatus === 'error', [generalStatus]);

  const exibeBreakPoint = useMemo(
    () =>
      passoExecutado.breakpoint &&
      passoExecutado.executado &&
      passoExecutado.id === passosExecutados.slice(-1)[0].id,
    [passoExecutado, passosExecutados],
  );

  const submitText = React.useMemo(() => {
    if (enablePreescrever || selectedRedirects.length >= 1) {
      return passoExecutado.final
        ? 'Prescrever e finalizar'
        : 'Prescrever e prosseguir';
    }

    return passoExecutado.final ? 'Finalizar e fechar' : 'Prosseguir';
  }, [enablePreescrever, passoExecutado.final, selectedRedirects.length]);

  const canRefazer = useMemo(() => {
    return (
      !isProtocoloReadOnly &&
      !exibeBreakPoint &&
      passoExecutado.executado &&
      !protocoloExecutado?.executado &&
      ((passoExecutado.executado_por &&
        passoExecutado.executado_por === user?.username) ||
        !passoExecutado.executado_por)
    );
  }, [
    exibeBreakPoint,
    isProtocoloReadOnly,
    passoExecutado.executado,
    passoExecutado.executado_por,
    protocoloExecutado?.executado,
    user?.username,
  ]);

  const passoType: TipoPasso = React.useMemo(() => {
    if (solicitacoes.some(({ tipo }) => tipo === 'condicao')) {
      return TipoPasso.CONDITION;
    }

    if (passoExecutado.breakpoint) {
      return TipoPasso.BREAKPOINT;
    }

    return TipoPasso.QUESTIONNARIE;
  }, [passoExecutado.breakpoint, solicitacoes]);

  const dispatch = useCallback(
    (dispachPayload: ExecutarPassoPayload) => {
      return {
        [TipoPasso.QUESTIONNARIE]: dispatchExecutarPasso,
        [TipoPasso.CONDITION]: dispatchExecutarPasso,
        [TipoPasso.BREAKPOINT]: dispatchPararPasso,
      }[passoType](dispachPayload);
    },
    [dispatchExecutarPasso, dispatchPararPasso, passoType],
  );

  const handleResposta = useCallback(
    (resposta: RespostaPayload) => {
      setPayload({ ...payload, ...resposta });
    },
    [payload],
  );

  const triggerExecutionFinishEvent = useCallback(
    (execucao?: ProtocoloExecutado) => {
      const eventObject = execucao || protocoloExecutado;
      if (passoExecutado.final && integracaoEnterpriseAtiva && eventObject) {
        // disabled - Goclin - Aguardando Implementação
        // medflowExecutionFinish(eventObject);
      }
    },
    [integracaoEnterpriseAtiva, passoExecutado.final, protocoloExecutado],
  );

  const handleRefazer = useCallback(async () => {
    if (solicitacoes.length) {
      try {
        setIsCancelandoArtefatos(true);
        const { results } = await IntegracaoService.requestCancelarArtefatos(
          passoExecutado.id,
          {
            solicitacoes: solicitacoes.map(solicitacao => solicitacao.id),
          },
        );
        setSolicitacoes(results);
      } finally {
        setIsCancelandoArtefatos(false);
      }
    }
    dispatchRefazerPasso({ origem: passoExecutado });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passoExecutado, dispatchRefazerPasso]);

  const handleRedirecionar = useCallback(async () => {
    if (
      mode !== 'integrated' &&
      selectedRedirects.length === 1 &&
      !redirectModalOpen
    ) {
      setRedirectModalOpen(true);
      return;
    }

    setIsProsseguirLoading(true);

    const [execucao, created] = await ExecucaoAPI.redirecionarFluxo({
      passoExecutado,
      opcoes: selectedRedirects,
      sugerir: mode === 'integrated', // Execução integrada sempre sugere
    });

    triggerExecutionFinishEvent(execucao);

    if (created) {
      dispatchSetExecucao(execucao);
      dispatchStartFromExecution(execucao);
    } else {
      toast.success(
        () => (
          <ToastContent Left={<FiCheck size={24} />}>
            Protocolos encaminhados com sucesso.
          </ToastContent>
        ),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
      dispatchProsseguirPasso({ passoExecutado, protocoloExecutado: execucao });
    }

    setIsProsseguirLoading(false);
  }, [
    dispatchProsseguirPasso,
    dispatchSetExecucao,
    dispatchStartFromExecution,
    mode,
    passoExecutado,
    redirectModalOpen,
    selectedRedirects,
    triggerExecutionFinishEvent,
  ]);

  const handlePrescreverExecutar = useCallback(async () => {
    try {
      if (passoExecutado.final && !integracaoEnterpriseAtiva) {
        dispatchSolicitarFinalizacao({ respostas: payload });

        return;
      }

      setIsProsseguirLoading(true);

      if (
        passoExecutado.solicitacoes.length &&
        generalStatus !== 'none_selected'
      ) {
        const response = await dispatchSolicitarRecursos(passoExecutado.id);
        if (response) {
          setSolicitacoes(response);
          await dispatch({ passoExecutado, respostas: payload });
        }
      } else {
        await dispatch({ passoExecutado, respostas: payload });
      }
    } catch (error) {
      setInError(true);
      dispatchAlterarStatus({ status: 'on-error' });
    } finally {
      setIsProsseguirLoading(false);
      triggerExecutionFinishEvent();
    }
  }, [
    passoExecutado,
    integracaoEnterpriseAtiva,
    generalStatus,
    dispatchSolicitarFinalizacao,
    payload,
    dispatchSolicitarRecursos,
    dispatch,
    dispatchAlterarStatus,
    triggerExecutionFinishEvent,
  ]);

  const handleCancelarPrescricao = useCallback(() => {
    if (enableRetry) {
      dispatchCancelarRecursos();
    }
  }, [enableRetry, dispatchCancelarRecursos]);

  const handleOnProximaEtapa = useCallback(async () => {
    try {
      setIsProsseguirLoading(true);
      if (protocoloExecutado) {
        await dispatchProsseguirPasso({
          passoExecutado: passosExecutados.slice(-1)[0],
          protocoloExecutado,
        });
      }
    } finally {
      dispatchAlterarStatus({ status: 'active' });
      setIsProsseguirLoading(false);
    }
  }, [
    dispatchProsseguirPasso,
    dispatchAlterarStatus,
    passosExecutados,
    protocoloExecutado,
  ]);

  const handleRequireAnswers = React.useCallback(() => {
    if (!inError) {
      const items = listInvalidRespostaPayload(
        passoExecutado,
        payload,
        allowNullItems,
      );

      boxPerguntasRef.current?.require(items);
    }
  }, [allowNullItems, inError, passoExecutado, payload]);

  useEffect(() => {
    return () => {
      dispatchCancelarRecursos();
    };
  }, [dispatchCancelarRecursos]);

  const ConditionContent = (
    <ConditionAlert
      step={passoExecutado}
      readonly={isProtocoloReadOnly || passoExecutado.executado}
      conditions={
        solicitacoes.filter(
          solic => solic.tipo === 'condicao',
        ) as CondicaoSolicitacaoType[]
      }
      onConditionsConfirmed={conditions => {
        setSolicitacoes(prev =>
          prev.map(req => {
            const conditionConfirmed = conditions.find(
              condition => condition.id === req.id,
            );
            return (conditionConfirmed as FhirArtefatoType) || req;
          }),
        );

        handlePrescreverExecutar();
      }}
      onConditionsRefuted={() => {
        handlePrescreverExecutar();
      }}
    />
  );

  const QuestionnaireContent = (
    <>
      <Header>
        <h2>{passoExecutado.nome}</h2>
      </Header>

      <Content>
        {passoExecutado.descricao && (
          <DescriptionContainer>
            <Markdown text={passoExecutado.descricao} />
          </DescriptionContainer>
        )}

        {passoExecutado.opcoes_redirecionamento.length > 0 && (
          <>
            {mode !== 'integrated' && selectedRedirects.length === 1 && (
              <ModalRedirecionarProtocolo
                isOpen={redirectModalOpen}
                onRedirecionar={handleRedirecionar}
                onClose={() => setRedirectModalOpen(false)}
              />
            )}
            <BoxSugestoesProtocolos
              disabled={passoExecutado.executado}
              options={passoExecutado.opcoes_redirecionamento}
              value={selectedRedirects}
              onChange={setSelectedRedirects}
              multiple={integracaoEnterpriseAtiva}
            />
          </>
        )}

        {passoExecutado.perguntas.length > 0 && (
          <BoxPerguntas
            ref={boxPerguntasRef}
            passoExecutado={passoExecutado}
            handleResposta={handleResposta}
          />
        )}

        {passoExecutado.solicitacoes.length > 0 && (
          <BoxSolicitacoes
            passoExecutado={passoExecutado}
            solicitacoes={solicitacoes}
            integracaoEnterpriseAtiva={integracaoEnterpriseAtiva}
          />
        )}
      </Content>

      {passoExecutado.solicitacoes.length > 0 && generalStatus === 'error' && (
        <Information type="danger">
          <ErrorBox
            handleCancel={handleCancelarPrescricao}
            handleRetry={handlePrescreverExecutar}
          />
        </Information>
      )}

      {!passoExecutado.executado && (
        <Footer>
          <ButtonOpenFlowchart style={{ marginRight: 'auto' }} />

          <Feature
            name="INTERROMPER_PROTOCOLO"
            fallback={<ButtonSairProtocolo />}
          >
            <ButtonInterromperProtocolo />
          </Feature>
          <Button
            theme="primary"
            aria-disabled={!canExecutar}
            loading={isProsseguirLoading}
            onClick={() => {
              if (selectedRedirects.length >= 1) {
                handleRedirecionar();
              } else {
                handlePrescreverExecutar();
              }
            }}
            onAriaDisabledClick={handleRequireAnswers}
          >
            {submitText}
          </Button>
        </Footer>
      )}
    </>
  );

  const BreakPointContent = exibeBreakPoint ? (
    <PassoExecutadoBreakpoint handleOnProximaEtapa={handleOnProximaEtapa} />
  ) : (
    QuestionnaireContent
  );

  const PassoContentMap: { [T in TipoPasso]: JSX.Element } = {
    [TipoPasso.QUESTIONNARIE]: QuestionnaireContent,
    [TipoPasso.CONDITION]: ConditionContent,
    [TipoPasso.BREAKPOINT]: BreakPointContent,
  };

  return (
    <Container ref={ref}>
      {canRefazer && (
        <RefazerButton
          theme="secondary"
          onClick={handleRefazer}
          loading={isCancelandoArtefatos}
          passoType={passoType}
        >
          Refazer
        </RefazerButton>
      )}

      {PassoContentMap[passoType]}
    </Container>
  );
});

export default PassoExecutadoDetail;
