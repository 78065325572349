import React from 'react';

import { TextField } from '@/components/InputField';

import useAutoUpdateInput from '@/features/execucao/hooks/useAutoUpdateInput';
import { InputPergunta } from '../../types';

const LargeInputString: InputPergunta = ({
  pergunta,
  disabled,
  nullable,
  onChange,
}) => {
  const {
    nome,
    meta: { placeholder, max_length, min_length },
  } = pergunta.variavel;

  const [value, setValue] = useAutoUpdateInput(pergunta);

  const [hasValidationError, setHasValidationError] = React.useState<
    null | 'min' | 'max'
  >(null);

  const [validationError, setValidationError] = React.useState<
    string | undefined
  >();

  const helperText = React.useMemo(() => {
    if (min_length) {
      return `Mínimo de ${min_length} caracteres`;
    }

    return undefined;
  }, [min_length]);

  const onDisplayError = React.useCallback(() => {
    setValidationError(undefined);

    if (hasValidationError === 'max') {
      setValidationError(
        `A quantidade de caracteres não pode ser maior que ${max_length}.`,
      );
    }

    if (hasValidationError === 'min') {
      setValidationError(
        `A quantidade de caracteres não pode ser menor que ${min_length}`,
      );
    }
  }, [hasValidationError, max_length, min_length]);

  const onValidate = React.useCallback(
    (toValidateValue: string) => {
      const hasValidation =
        (max_length !== undefined || min_length !== undefined) &&
        toValidateValue !== undefined;

      setHasValidationError(null);

      if (hasValidation && min_length && toValidateValue.length < min_length) {
        setHasValidationError('min');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }

      if (hasValidation && max_length && toValidateValue.length > max_length) {
        setHasValidationError('max');
        if (onChange) {
          onChange({
            [nome]: null,
          });
        }
      }
    },
    [max_length, min_length, nome, onChange],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value: inputValue },
      } = event;

      setValue(inputValue);

      if (onChange) {
        onChange({
          [nome]: inputValue,
        });
        onValidate(inputValue);
      }
    },
    [setValue, onChange, nome, onValidate],
  );

  React.useEffect(() => {
    if (onChange && typeof nullable === 'boolean') {
      onChange({ [nome]: nullable ? null : '' });

      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullable]);

  return (
    <TextField
      placeholder={placeholder}
      maxLength={max_length}
      minLength={min_length}
      value={value}
      rows={6}
      style={{
        width: 400,
      }}
      error={validationError}
      onBlur={onDisplayError}
      onChange={handleChange}
      showCharCount={!!(min_length || max_length)}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default LargeInputString;
