import React from 'react';
import spinner from '@/assets/spinner.svg';

import { Checkbox } from '@/components/SelectionControl';
import { humanizeAndPluralize } from '../helpers';

import {
  BoxSelection,
  BoxSelectionTitle,
  CheckboxItem,
  CheckboxItemTitle,
  SpinnerContainer,
} from './styles';

interface SelectCheckboxProps {
  type: string;
  disabled: boolean;
  generalStatus: string;
  titleText: string;
  checkboxState: 'unmarked' | 'marked' | 'half-marked';
  handleAllOptionsSelection: () => void;
}

const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  type,
  handleAllOptionsSelection,
  generalStatus,
  disabled,
  checkboxState,
  titleText,
}) => {
  return (
    <BoxSelection>
      <BoxSelectionTitle>
        {`Selecione ${humanizeAndPluralize[type]} que você quer solicitar no seu prontuário.`}
      </BoxSelectionTitle>
      <CheckboxItem
        onClick={() => handleAllOptionsSelection()}
        selected={['all_selected', 'some_selected', 'error'].includes(
          generalStatus,
        )}
        disabled={disabled}
      >
        {generalStatus === 'loading' ? (
          <SpinnerContainer>
            <img src={spinner} alt="Carregando..." />
          </SpinnerContainer>
        ) : (
          <Checkbox state={checkboxState} disabled={disabled} size="small" />
        )}
        <CheckboxItemTitle>{titleText}</CheckboxItemTitle>
      </CheckboxItem>
    </BoxSelection>
  );
};

export default SelectCheckbox;
