import {
  ContextoIntegracao,
  ProtocoloExecutado,
  StatusExecucao,
  StatusIntegracao,
} from './ProtocoloExecutado';

export type MedflowEventType = 'closeMedflowApp' | 'medflowExecutionFinish';

export interface MedflowEventData<TContent> {
  type: MedflowEventType;
  content: TContent;
}

export interface IntegracaoEventData {
  id: string;
  statusIntegracao: StatusIntegracao;
  encounterId: string;
  encounterUrl: string;
  bundleId: string;
  bundleUrl: string;
}

export interface IntegracaoProtocoloEventData extends IntegracaoEventData {
  codigo: string;
  nome: string;
}

export interface ExecucaoEventData extends IntegracaoProtocoloEventData {
  status: StatusExecucao;
  destino: IntegracaoProtocoloEventData[];
}

export function toIntegracaoEventData(
  context: ContextoIntegracao,
): IntegracaoEventData {
  const {
    id,
    bundle_id: bundleId,
    bundle_url: bundleUrl,
    encounter_id: encounterId,
    encounter_url: encounterUrl,
    status_integracao: statusIntegracao,
  } = context;

  return {
    id,
    statusIntegracao,
    bundleId,
    bundleUrl,
    encounterId,
    encounterUrl,
  };
}

export function toExecucaoEventData(
  protocolo: ProtocoloExecutado,
): ExecucaoEventData {
  if (!protocolo.contexto_integracao) {
    throw new Error('Execução de protocolo precisa ser integrada!');
  }

  return {
    nome: protocolo.nome,
    codigo: protocolo.codigo,
    status: protocolo.status,
    ...toIntegracaoEventData(protocolo.contexto_integracao),
    destino: protocolo.destino.map(target => ({
      codigo: target.nome,
      nome: target.nome,
      ...toIntegracaoEventData(target.contexto_integracao),
    })),
  };
}
