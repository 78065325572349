import React from 'react';

import { usePatientDisplay } from '@/features/enterprise/hooks/useEncounter';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import {
  PatientBox,
  PatientBoxContent,
  PatientContainer,
  PatientContainerTitle,
  PatientImage,
  PatientInfo,
  PatientInfoItem,
  PatientInfoItemTitle,
  PatientInfoItemValue,
  PatientRow,
} from './styles';

interface PatientSectionProps {
  mode?: 'default' | 'clean';
}

const PatientSection: React.FC<PatientSectionProps> = ({ mode }) => {
  const [{ isProtocoloReadOnly }] = useExecucaoContext();
  const {
    usualName,
    officialName,
    age,
    gender,
    avatar,
    hasMinimalDisplayProperties,
  } = usePatientDisplay();

  if (!hasMinimalDisplayProperties) {
    return <></>;
  }

  if (mode === 'clean') {
    return (
      <PatientRow>
        <PatientImage src={avatar} />
        <PatientInfo style={{ justifyContent: 'center' }}>
          <PatientInfoItemTitle>
            {usualName || officialName || 'Paciente'}
          </PatientInfoItemTitle>

          {age && <PatientInfoItemValue>{age}</PatientInfoItemValue>}
        </PatientInfo>
      </PatientRow>
    );
  }

  return (
    <PatientContainer>
      <PatientContainerTitle>
        {isProtocoloReadOnly ? 'Dados do paciente' : 'Dados do seu paciente'}
      </PatientContainerTitle>
      <PatientBox>
        <PatientBoxContent>
          <PatientImage src={avatar} />
          <PatientInfo>
            {(usualName || officialName) && (
              <PatientInfoItem>
                <PatientInfoItemTitle>
                  {usualName || 'Nome'}
                </PatientInfoItemTitle>
                <PatientInfoItemValue>{officialName}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
            {age && (
              <PatientInfoItem>
                <PatientInfoItemTitle>Idade</PatientInfoItemTitle>
                <PatientInfoItemValue>{age}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
            {gender && (
              <PatientInfoItem>
                <PatientInfoItemTitle>Sexo</PatientInfoItemTitle>
                <PatientInfoItemValue>{gender}</PatientInfoItemValue>
              </PatientInfoItem>
            )}
          </PatientInfo>
        </PatientBoxContent>
      </PatientBox>
    </PatientContainer>
  );
};

PatientSection.defaultProps = {
  mode: 'default',
};

export default PatientSection;
