/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react';

import {
  AddonLabel,
  Field,
  HelperText,
  Input,
  InputContent,
  Textarea,
  Title,
  CharCount,
  CharCountCountainer,
} from './styles';

interface FieldLayoutProps extends React.PropsWithChildren {
  title?: string;
  error?: string;
  style?: CSSProperties;
  helperText?: string;
}

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  inputTitle?: string; // Standart HTML title attribute
  addon?: string;
  error?: string;
  children?: React.ReactNode;
}

export interface TextFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  inputTitle?: string; // Standart HTML title attribute
  error?: string;
  showCharCount?: boolean;
  helperText?: string;
}

const FieldLayout: React.FC<FieldLayoutProps> = ({
  error,
  title,
  style,
  children,
  helperText,
}) => (
  <Field hasError={!!error} style={style}>
    {title && <Title>{title}</Title>}
    <InputContent>{children}</InputContent>
    {helperText && <HelperText>{helperText}</HelperText>}
    {error && <HelperText>{error}</HelperText>}
  </Field>
);

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  ({ title, addon, error, children, inputTitle, ...inputProps }, ref) => {
    return (
      <FieldLayout error={error} title={title} style={inputProps.style}>
        <Input {...inputProps} ref={ref} title={inputTitle} />
        {addon && <AddonLabel>{addon}</AddonLabel>}
        {children}
      </FieldLayout>
    );
  },
);

const charCountText = (count = 0, maxLength?: number) => {
  let countText = `${count}`;

  if (maxLength) {
    countText += `/${maxLength}`;
  }

  return countText;
};

export const TextField = React.forwardRef<HTMLTextAreaElement, TextFieldProps>(
  (
    {
      title,
      value,
      error,
      inputTitle,
      maxLength,
      disabled,
      showCharCount = false,
      helperText,
      ...textareaProps
    },
    ref,
  ) => {
    const textareaInnerRef = React.useRef<HTMLTextAreaElement>(null);
    const charCountRef = React.useRef<HTMLSpanElement>(null);

    const setCharCountText = React.useCallback(
      (count: number) => {
        if (charCountRef.current) {
          charCountRef.current.innerHTML = charCountText(count, maxLength);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [maxLength, showCharCount],
    );

    const handleInput = React.useCallback(
      (event: Event) => {
        const target = event.target as HTMLTextAreaElement;

        setCharCountText(target.value.length);
      },
      [setCharCountText],
    );

    React.useEffect(() => {
      const textareaNode = textareaInnerRef.current;
      if (textareaNode) {
        textareaNode.addEventListener('input', handleInput);
      }

      return () => {
        if (textareaNode) {
          textareaNode.removeEventListener('input', handleInput);
        }
      };
    }, [handleInput]);

    React.useEffect(() => {
      if (textareaInnerRef.current) {
        setCharCountText(textareaInnerRef.current.value.length);
      }
    }, [setCharCountText]);

    React.useEffect(() => {
      if (value !== undefined) {
        setCharCountText(value.toLocaleString().length);
      }
    }, [setCharCountText, value]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    React.useImperativeHandle(ref, () => textareaInnerRef.current!, []);

    return (
      <FieldLayout error={error} title={title} helperText={helperText}>
        <Textarea
          ref={textareaInnerRef}
          maxLength={maxLength}
          title={inputTitle}
          value={value}
          disabled={disabled}
          {...textareaProps}
        />
        {showCharCount && (
          <CharCountCountainer>
            <CharCount ref={charCountRef} />
          </CharCountCountainer>
        )}
      </FieldLayout>
    );
  },
);

export default InputField;
