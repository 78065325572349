import colors from '@/design/colors';
import { HeadingLarge, Text } from '@/design/typography';
import styled, { keyframes } from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 17px;
  }

  h3 {
    color: ${colors.black};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  p {
    color: ${colors.gray300};
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
    max-width: 400px;
    text-align: center;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 400px;
  height: 100vh;

  justify-content: center;
  align-items: left;

  img {
    width: 100px;
    margin-bottom: 12px;
  }

  h3 {
    color: ${colors.black};

    ${HeadingLarge(18)};

    margin-bottom: 8px;
  }

  p {
    color: ${colors.gray300};

    ${Text(14)};
    margin-bottom: 15px;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 6.5px;
  flex-shrink: 0;
  border-radius: 999px;
  background: ${colors.gray100};
`;

const progressBar = keyframes`
  0% { width: 0; }
  33% { width: 33%; }
  66% { width: 66%; }
  100% { width: 100%; }
`;

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 6.5px;
  border-radius: 999px;
  background: ${colors.purple100};
  animation: ${progressBar} 3s forwards infinite;
`;
