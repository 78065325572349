import React, { useCallback, useEffect, useMemo, useState } from 'react';

import OptionIntegracao from '@/types/OptionIntegracao';
import { groupBy } from '@/utils/collections';

import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import CategoriaArtefato from '@/features/execucao/components/CategoriaArtefato';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import Feature from '@/features/features/components/Feature';
import BoxCondutaComplementar from './BoxCondutaComplementar';
import { getSelectionControlState, pluralize, singularize } from './helpers';
import OptionItem from './Item';
import SelectCheckbox from './SelectCheckbox';
import { Container } from './styles';

interface SelectCheckboxIntegracaoProps {
  type:
    | 'Procedimento'
    | 'Exame'
    | 'Medicamento'
    | 'Encaminhamento'
    | 'Orientacao'
    | 'Fotoceutico';
  options: OptionIntegracao[];
  generalStatus: string;
  totalSolicitados?: number;
  onChange?: (
    selectedOptions: OptionIntegracao[],
    enabledOptions?: OptionIntegracao[],
  ) => void;
}

const SelectCheckboxIntegracao: React.FC<SelectCheckboxIntegracaoProps> = ({
  type,
  options,
  generalStatus,
  totalSolicitados,
  onChange,
}: SelectCheckboxIntegracaoProps) => {
  const [selectOptions, setSelectOptions] = useState<OptionIntegracao[]>([]);

  const [{ isProtocoloReadOnly }] = useExecucaoContext();

  const [{ generalStatus: globalStatus }] = useIntegrationContext();

  const disabled = useMemo(
    () => generalStatus === 'done' || generalStatus === 'disabled',
    [generalStatus],
  );

  const disableClick = useMemo(
    () =>
      generalStatus === 'done' ||
      generalStatus === 'disabled' ||
      generalStatus === 'loading',
    [generalStatus],
  );

  const checkboxState = useMemo((): 'unmarked' | 'marked' | 'half-marked' => {
    if (
      generalStatus === 'all_selected' ||
      generalStatus === 'done' ||
      generalStatus === 'disabled'
    )
      return 'marked';
    if (generalStatus === 'some_selected') return 'half-marked';

    return 'unmarked';
  }, [generalStatus]);

  const titleText = useMemo(() => {
    if (generalStatus === 'done' && totalSolicitados) {
      if (options.length === totalSolicitados) {
        return totalSolicitados > 1 ? pluralize[type] : singularize[type];
      }

      return `${totalSolicitados} ${
        totalSolicitados > 1 ? pluralize[type] : singularize[type]
      }`;
    }

    return 'Selecionar todos';
  }, [generalStatus, options, totalSolicitados, type]);

  const condutaComplementarFeatureName = React.useMemo(() => {
    return `CONDUTA_COMPLEMENTAR__${type.toUpperCase()}`;
  }, [type]);

  const artefatosPossuemCategorias = useMemo(() => {
    return options.filter(option => !!option.categoria).length > 0;
  }, [options]);

  const hasFhirRequest = useCallback(
    (option: OptionIntegracao) => !!option.pedido,
    [],
  );

  const handleAllOptionsSelection = useCallback(() => {
    if (disableClick) return;

    const newOptions = selectOptions.map(opt => ({
      ...opt,
      selected: hasFhirRequest(opt) && generalStatus === 'none_selected',
    }));

    setSelectOptions(newOptions);

    if (onChange) {
      onChange(
        newOptions.filter(opt => opt.selected),
        newOptions,
      );
    }
  }, [disableClick, generalStatus, selectOptions, onChange, hasFhirRequest]);

  const handleOptionSelection = useCallback(
    (option: OptionIntegracao) => {
      if (disableClick || !!option.status) return;
      let newOptions = selectOptions;

      if (selectOptions.includes(option)) {
        newOptions = selectOptions.map(opt => {
          if (opt.key === option.key) {
            return { ...opt, selected: !opt.selected };
          }

          return opt;
        });
      } else {
        newOptions = [...selectOptions, option];
      }

      setSelectOptions(newOptions);

      if (onChange) {
        onChange(
          newOptions.filter(opt => opt.selected),
          newOptions,
        );
      }
    },
    [disableClick, selectOptions, onChange],
  );

  const handleCategoryOptionsSelection = useCallback(
    (categoryOptions: OptionIntegracao[]) => {
      const alreadySelected = categoryOptions.every(item => item.selected);
      const pedidosIDs = categoryOptions.map(item => item.pedido.id);

      const newOptions = selectOptions.map(opt => {
        if (pedidosIDs.includes(opt.pedido.id)) {
          return { ...opt, selected: !alreadySelected };
        }

        return opt;
      });

      setSelectOptions(newOptions);

      if (onChange) {
        onChange(newOptions.filter(item => item.selected));
      }
    },
    [onChange, selectOptions],
  );

  useEffect(() => {
    if (globalStatus === 'none_selected') {
      const newOptions = selectOptions.map(opt => ({
        ...opt,
        selected: false,
      }));
      setSelectOptions(newOptions);

      if (onChange) {
        onChange(newOptions.filter(opt => opt.selected));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, globalStatus, onChange]);

  useEffect(() => {
    const innerOptions = options.map((opt, index) => ({
      ...opt,
      key: opt.value.toString() || index.toString(),
      selected:
        opt.selected || (opt.pedido.status === 'draft' && opt.selectedDefault),
    }));

    setSelectOptions(innerOptions);

    if (onChange) {
      onChange(innerOptions.filter(opt => opt.selected));
    }
  }, [onChange, options]);

  const ContentCategorias = useMemo(() => {
    const groupedArtefatos = groupBy(selectOptions, option =>
      option.pedido.intent === 'order'
        ? 'Itens complementares'
        : option.categoria || 'Outros',
    );
    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        name={key}
        controlProps={{
          disabled: disableClick,
          state: getSelectionControlState(value),
          onClick: () => handleCategoryOptionsSelection(value),
        }}
        totalSelected={value.filter(item => item.selected).length}
        artefatos={value.map((opt, index) => (
          <OptionItem
            key={opt.key || index.toString()}
            type={type}
            option={opt}
            selected={!!opt.selected}
            disabled={
              opt.status === 'solicitado' || !hasFhirRequest(opt) || disabled
            }
            included={!!opt.included}
            loading={!!opt.selected && generalStatus === 'loading'}
            error={generalStatus === 'error'}
            handleClick={handleOptionSelection}
          />
        ))}
      />
    ));
  }, [
    selectOptions,
    disableClick,
    handleCategoryOptionsSelection,
    type,
    hasFhirRequest,
    disabled,
    generalStatus,
    handleOptionSelection,
  ]);

  return (
    <Container>
      {!isProtocoloReadOnly && (
        <SelectCheckbox
          type={type}
          disabled={disabled}
          generalStatus={generalStatus}
          titleText={titleText}
          checkboxState={checkboxState}
          handleAllOptionsSelection={handleAllOptionsSelection}
        />
      )}

      {artefatosPossuemCategorias ? (
        <>{ContentCategorias}</>
      ) : (
        <>
          {selectOptions.map((opt, index) => (
            <OptionItem
              key={opt.key || index.toString()}
              type={type}
              option={opt}
              selected={!!opt.selected}
              disabled={
                opt.status === 'solicitado' || !hasFhirRequest(opt) || disabled
              }
              included={!!opt.included}
              error={generalStatus === 'error'}
              loading={!!opt.selected && generalStatus === 'loading'}
              handleClick={handleOptionSelection}
            />
          ))}
        </>
      )}

      {!disabled && (
        <Feature name={condutaComplementarFeatureName}>
          <BoxCondutaComplementar
            type={type}
            disabled={disabled}
            handleOptionConfirm={handleOptionSelection}
          />
        </Feature>
      )}
    </Container>
  );
};

SelectCheckboxIntegracao.defaultProps = {
  totalSolicitados: undefined,
  onChange: undefined,
};

export default SelectCheckboxIntegracao;
