import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SelectCheckboxIntegracao from '@/features/enterprise/components/SelectCheckboxIntegracao';
import { useIntegrationContext } from '@/features/enterprise/contexts/IntegrationContext';
import { FhirArtefatoType } from '@/features/enterprise/models/types';

import { PassoExecutado } from '@/models/PassoExecutado';

import OptionIntegracao from '@/types/OptionIntegracao';
import useIntegration from '../../../hooks/useIntegration';

import { Content, Wrapper } from './styles';

interface ProcedimentosProps {
  procedimentos: FhirArtefatoType[];
  passo: PassoExecutado;
}

const ListaProcedimentosFhir: React.FC<ProcedimentosProps> = ({
  procedimentos,
  passo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionIntegracao[]>(
    [],
  );

  const [enabledOptions, setEnabledOptions] = useState<OptionIntegracao[]>([]);

  const {
    integrationItems,
    integrationStatus,
    integrationTotalSelected,
    changeIntegrationStatus,
    toOptionIntegracao,
  } = useIntegration({ items: procedimentos, passoExecutado: passo });

  const [
    { procedimentosStatus },
    ,
    { updateProcedimentosStatus },
  ] = useIntegrationContext();

  const options = useMemo(() => {
    return (integrationItems as FhirArtefatoType[]).map(
      (procedimento, index) => {
        const option = {
          key: `${index}`,
          title: procedimento.titulo,
          detail: procedimento.descricao,
          categoria: procedimento.categoria,
          value: procedimento.id,
          edited: procedimento.editado,
          included: procedimento.solicitacao_incluida,
          pedido: procedimento.pedido,
          selectedDefault: !!procedimento.solicitacao_pre_selecionada,
        } as OptionIntegracao;

        return toOptionIntegracao(option);
      },
    );
  }, [integrationItems, toOptionIntegracao]);

  const handleChange = useCallback(
    (selOpts: OptionIntegracao[], enOpts?: OptionIntegracao[]) => {
      if (enOpts) {
        setEnabledOptions(enOpts);
      }
      setSelectedOptions(selOpts);
    },
    [],
  );

  useEffect(() => {
    updateProcedimentosStatus(integrationStatus, selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationStatus, selectedOptions]);

  useEffect(() => {
    if (selectedOptions && integrationStatus !== 'disabled') {
      changeIntegrationStatus(selectedOptions.length, enabledOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    setEnabledOptions(options);
  }, [options]);

  return (
    <Wrapper>
      <Content>
        <SelectCheckboxIntegracao
          type="Procedimento"
          options={options}
          generalStatus={passo.executado ? 'disabled' : procedimentosStatus}
          totalSolicitados={integrationTotalSelected}
          onChange={handleChange}
        />
      </Content>
    </Wrapper>
  );
};

export default ListaProcedimentosFhir;
